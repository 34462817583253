import React from "react";
import ResponsiveImage from "@components/atoms/image/ResponsiveImage";

type DownloadAppUIProps = {
  id?: string;
  href?: string;
  image: {
    dimensions: string[];
    prefixes: {
      mobile: string;
      dpi: string;
    };
    imgClass?: string;
  } & React.ImgHTMLAttributes<HTMLImageElement>;
};

export default function DownloadAppUI({
  data,
}: {
  data: DownloadAppUIProps[];
}) {
  return data.map((item, count: number) => {
    return (
      <a
        href={item.href}
        rel="noopener noreferrer"
        target="_blank"
        id={item.id}
        key={count}
      >
        <ResponsiveImage
          src={item.image.src}
          alt={item.image.alt}
          width={item.image.dimensions[0]}
          height={item.image.dimensions[1]}
          mobile={item.image.prefixes.mobile}
          dpi={item.image.prefixes.dpi}
          hasMobileImg={false}
          className={item.image.imgClass}
        />
      </a>
    );
  });
}
